import React, { useState, useEffect } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import * as api from "../../services/ApiService";
import Modal from "react-bootstrap/Modal";

function SubmissionFileComponent({ user }) {
  const [incompleteTabs, setIncompleteTabs] = useState({});
  const [hasProfileCompleted, setHasProfileCompleted] = useState(null);
  const navigate = useNavigate();
  const fetchEducationalInfoData = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-submission-file-data");

      if (apiData.has_profile_completed === false) {
        setHasProfileCompleted(false);
        setIncompleteTabs(apiData.incomplete_tabs);
      } else {
        setHasProfileCompleted(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchEducationalInfoData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  // Handle button click to show appropriate modal
  const handleCreateSubmissionFile = () => {
    if (hasProfileCompleted === false) {
      setErrorModalOpen(true);
    } else if (hasProfileCompleted === true) {
      setSuccessModalOpen(true);
    }
  };

  const [documentLists, setDocumentLists] = useState({});
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [isPromptVisible, setIsPromptVisible] = useState(true);
  const [isDocumentSelectionVisible, setIsDocumentSelectionVisible] = useState(false);
  const [isSubmissionReady, setIsSubmissionReady] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleSuccessModal = () => {
    setSuccessModalOpen(!successModalOpen);

    setIsPromptVisible(true);
    setIsDocumentSelectionVisible(false);
    setIsSubmissionReady(false);
  };

  const toggleErrorModal = () => {
    setErrorModalOpen(!errorModalOpen);
  };

  const fetchDocuments = async () => {
    try {
      const param = {
        user_id: user.id,
      };
      const result = await api.postMethod(param, "user/get-documents");

      if (result.status) {
        const apiData = result.data;
        const apiDataObj = apiData.reduce((acc, currentValue, index) => {
          acc[index] = currentValue;
          return acc;
        }, {});

        setDocumentLists(apiDataObj);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Stop loading once data is fetched or an error occurs
    }
  };

  // Check if document lists are already stored in localStorage
  useEffect(() => {
    const storedDocumentLists = JSON.parse(localStorage.getItem("submission_document_lists"));

    if (storedDocumentLists && 0) {
      setSelectedDocuments(storedDocumentLists); // Set selected docs from localStorage
      setIsSubmissionReady(true); // Directly show the submission button
      setLoading(false);
    } else {
      fetchDocuments();
      setIsPromptVisible(true);
    }
  }, []);

  // Handle user response to the prompt
  const handlePromptResponse = (response) => {
    if (response === "yes") {
      setIsDocumentSelectionVisible(true); // Show document selection
    } else {
      setIsSubmissionReady(true); // Directly show the submission button
      setIsDocumentSelectionVisible(false);
    }
    setIsPromptVisible(false); // Hide the prompt
  };

  // Handle checkbox change
  const handleCheckboxChange = (docId) => {
    const updatedDocuments = selectedDocuments.includes(docId)
      ? selectedDocuments.filter((id) => id !== docId)
      : [...selectedDocuments, docId];

    setSelectedDocuments(updatedDocuments);
  };

  // Store selected documents in localStorage and enable submission
  const handleDocumentSelection = () => {
    localStorage.setItem("submission_document_lists", JSON.stringify(selectedDocuments));
    setIsSubmissionReady(true); // Enable submission button
    setIsDocumentSelectionVisible(false); // Hide document selection
  };

  return (
    <>
      <div className="app-search">
        <div className="app-search-box">
          <span className="">
            <button className="btn-info btn" onClick={handleCreateSubmissionFile}>
              Create Submission File
            </button>
          </span>
        </div>
      </div>

      {/* Success Modal */}

      <Modal
        show={successModalOpen}
        onHide={toggleSuccessModal}
        centered
        backdrop="static"
        size="md"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {isPromptVisible && (
            <>
              <h4 className="text-center">
                <strong>Do you want to add additional documents?</strong>
              </h4>
              <p className="text-center">
                (Not required License Verification, Certifications, Reference Letters)
              </p>
              <div className="text-center">
                <button onClick={() => handlePromptResponse("yes")} className="btn btn-primary">
                  Yes
                </button>
                &nbsp;&nbsp;&nbsp;
                <button onClick={() => handlePromptResponse("no")} className="btn btn-secondary">
                  No
                </button>
              </div>
            </>
          )}

          {isSubmissionReady && (
            <>
              <h4 className="text-center">Click Below to Process your Submission File</h4>

              <div className="text-center">
                <Link
                  to={process.env.REACT_APP_FRONT_END_URL + `submission-file/` + user.unique_id}
                  className="btn btn-success"
                  target="_blank"
                >
                  Generate Submission File
                </Link>
              </div>
            </>
          )}

          {isDocumentSelectionVisible && (
            <>
              <h4 className="text-center">Select Additional Documents</h4>
              <h5 className="text-center">
                (Not required License Verification, Certifications, Reference Letters)
              </h5>

              <div>
                {loading ? (
                  <p>Loading documents...</p> // Show a loading message while documents are being fetched
                ) : Object.values(documentLists).length > 0 ? (
                  <>
                    {Object.values(documentLists).map((document) => (
                      <label key={document.id} class="me-2 d-block">
                        <input
                          type="checkbox"
                          checked={selectedDocuments.includes(document.id)}
                          onChange={() => handleCheckboxChange(document.id)}
                        />{" "}
                        {document.title}
                      </label>
                    ))}
                  </>
                ) : (
                  <>
                    <h5 className="text-center mb-3">
                      <strong>
                        No Document currently available <Link to="/user/documents">Click Here</Link>{" "}
                        to upload a Document.
                      </strong>
                    </h5>
                  </>
                )}

                {Object.values(documentLists).length > 0 && (
                  <button
                    className="btn btn-success"
                    onClick={handleDocumentSelection}
                    disabled={selectedDocuments.length === 0}
                  >
                    Store Selected Documents
                  </button>
                )}
              </div>

              <div className="text-center border-top pt-2 mt-3">
                <button onClick={() => handlePromptResponse("no")} className="btn btn-secondary">
                  Continue Without Document Selection
                </button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>

      {/* Incomplete Tabs Modal */}

      <Modal show={errorModalOpen} onHide={toggleErrorModal} centered backdrop="static" size="md">
        <Modal.Header closeButton>
          <Modal.Title className="my-0">
            Below are the items you will need to complete before you can process your Submission
            File. Click on the items below to begin.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="mb-0 ms-0 ps-0">
            {Object.keys(incompleteTabs).map((key) => (
              <li key={key} className="w-100 d-flex align-items-center">
                {/* Tab Title */}
                <h5 className="w-100 d-flex align-items-center">
                  <span style={{ marginRight: "10px", color: "red" }}>❌</span>{" "}
                  {incompleteTabs[key].title} {/* Check if tab is not empty, pass state */}
                  {incompleteTabs[key].tab ? (
                    <button
                      className="btn btn-rounded btn-xs btn-info float-end ms-auto"
                      onClick={() => {
                        toggleErrorModal();

                        navigate(incompleteTabs[key].link, {
                          state: { tab: incompleteTabs[key].tab },
                        });
                      }}
                    >
                      Complete Now
                    </button>
                  ) : (
                    /* If tab is empty, no state is passed */
                    <button
                      className="btn btn-rounded btn-xs btn-info float-end ms-auto"
                      onClick={() => navigate(incompleteTabs[key].link)}
                    >
                      Complete Now
                    </button>
                  )}
                </h5>
              </li>
            ))}

            <li key={0} className="w-100 d-flex align-items-center">
              {/* Tab Title */}
              <h5 className="w-100 d-flex align-items-center ps-3">
                &nbsp; Add Additional Documents
                <button
                  className="btn btn-rounded btn-xs btn-info float-end ms-auto"
                  onClick={() => navigate("/user/documents")}
                >
                  Add Now
                </button>
              </h5>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={toggleErrorModal}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SubmissionFileComponent;
