import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import Footer from "../../Layout/Footer";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";
import JobFilters from "./JobFilters";
import { formatAmount } from "../../../utils/AmountFormatter";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function ShortListedJobs() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const query = useQuery();

  const [isLoading, setIsLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  /* Job Message Submission */
  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    token: user.token,
    job_id: null,
    message: null,
  });

  const toggleModal = (job_id = null) => {
    if (job_id != null) {
      setformData({
        ...formData,
        job_id: job_id,
      });
    }
    setModalOpen(!modalOpen);
  };

  const [filters, setFilters] = useState({
    job_title: query.get("job_title") || "",
    state_id: query.get("state_id") || 0,
    no_of_opening: query.get("no_of_opening") || "",
    profession_id: query.get("profession_id") || 0,
    specialty_id: query.get("specialty_id") || 0,
    job_type: query.get("job_type") || "",
    order_by: query.get("order_by") || "default",
  });

  useEffect(() => {
    fetchJobData();
  }, [filters]);

  const updateFilters = (newFilters) => {
    setFilters(newFilters);
    fetchJobData();
  };

  const [jobs, setJobs] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchJobData = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };

      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "user/get-bookmarked-jobs");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      setJobs(apiData.data);
      setLoading(false);
      setNoRecordsFound(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchJobData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const toggleBookmarkJob = async (job_id) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        token: user.token,
        job_id: job_id,
      };

      const apiData = await api.postMethod(param, "user/update-job-bookmark");
      if (apiData.status) {
        fetchJobData();
        setIsLoading(false);
        toast.success(apiData.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return `${formattedDate}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedTime}`;
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    message: Yup.string().required("Message is Required"),
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const result = await api.postMethod(formData, "user/insert-job-message");
      if (result.status) {
        setformData({
          ...formData,
          message: null,
        });
        document.getElementById("message").value = "";
        setModalOpen(false);
        toast.success("Message has been sent!");
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Saved Jobs</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Saved Jobs</h4>
                  </div>
                </div>
              </div>
              {/* end page title */}
              <div className="row">
                <div className="col-12">
                  <JobFilters
                    fetchJobData={fetchJobData}
                    filters={filters}
                    onUpdateFilters={updateFilters}
                    curPage="savedJobs"
                  ></JobFilters>

                  {loading ? (
                    <div className="card mb-3 custom-box-shadow">
                      <div className="card-body">Loading...</div>
                    </div>
                  ) : (
                    <>
                      {noRecordsFound ? (
                        <div className="card mb-3 custom-box-shadow">
                          <div className="card-body">No record found</div>
                        </div>
                      ) : (
                        <>
                          {Object.entries(jobs).map(([key, value]) => (
                            <div
                              className="card mb-3 custom-box-shadow overflow-hidden position-relative"
                              key={value.id}
                            >
                              <div className="card-body">
                                <div className="row align-items-center">
                                  <div className="col-sm-12">
                                    <h5 className="mt-0 mb-2 font-16">
                                      <Link to={`/user/job/` + value.unique_id} className="">
                                        {value.title}{" "}
                                      </Link>
                                      <span className=" text-muted font-14 mb-1">
                                        ({value.company_name})
                                      </span>
                                      {/* 
                                      <span className="mb-0 text-muted">
                                        <small>(#{value.unique_id})</small>
                                      </span>
                                      */}
                                    </h5>
                                  </div>
                                  <div className="col-sm-4">
                                    <div className="d-flex align-items-start">
                                      <Link to={`/user/job/` + value.unique_id} className="">
                                        {value.profile_pic_path && (
                                          <img
                                            src={value.profile_pic_path}
                                            className="d-flex align-self-center me-3 rounded-circle"
                                            alt="user-img"
                                            height={64}
                                          />
                                        )}
                                      </Link>

                                      <div className="w-100">
                                        <p className="mb-1">
                                          <b>Location : </b>{" "}
                                          {value.state_code !== null && value.city_name !== null
                                            ? value.city_name + ", " + value.state_code
                                            : value.state_code !== null
                                            ? value.state_code
                                            : value.city_name !== null
                                            ? value.city_name
                                            : "N/A"}
                                        </p>
                                        <p className="mb-0">
                                          <b>Specalities : </b>{" "}
                                          {value.specialty !== null ? value.specialty : "-"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <p className="mb-1 mt-0 mt-sm-0">
                                      <b>Shifts : </b> {value.shift_title || "-"}
                                    </p>
                                    <p className="mb-0">
                                      <b>Salary : </b>
                                      {value.show_pay_rate == 0 ? (
                                        <>
                                          <span
                                            onClick={() => toggleModal(value.id)}
                                            className="cursor-pointer"
                                            style={{ cursor: "pointer" }}
                                          >
                                            Contact Us
                                          </span>
                                        </>
                                      ) : value.salary_start_range ? (
                                        <>
                                          ${formatAmount(value.salary_start_range)}{" "}
                                          {value.salary_type}
                                        </>
                                      ) : (
                                        "N/A"
                                      )}
                                    </p>
                                  </div>
                                  <div className="col-sm-2">
                                    <p className="mb-1 mt-0 mt-sm-0">
                                      <b>Total Opening : </b> {value.total_opening || "N/A"}
                                    </p>
                                    <p className="mb-0">
                                      <b>Posted On : </b> {formatDate(value.created_at)}
                                    </p>
                                  </div>
                                  <div className="col-sm-2">
                                    <div className="text-sm-end">
                                      <button
                                        type="button"
                                        className="btn btn-secondary btn-xs me-1"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="Send Message"
                                        onClick={() => toggleModal(value.id)}
                                      >
                                        <i className="mdi mdi-message" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                      <Link
                                        to={`/user/job/` + value.unique_id}
                                        className="btn btn-secondary btn-xs me-1"
                                        data-tooltip-id="mt-2"
                                        data-tooltip-content="View Job"
                                      >
                                        <i className="mdi mdi-eye" />
                                      </Link>
                                      <Tooltip id="mt-2"></Tooltip>
                                      <button
                                        type="button"
                                        className="btn btn-secondary btn-xs"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="Remove from Saved Job"
                                        onClick={() => toggleBookmarkJob(value.id)}
                                      >
                                        <i className="mdi mdi-trash-can" />
                                      </button>
                                      <Tooltip id="mt-3"></Tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {value.is_boosted == 1 && (
                                <div className="ribbon ribbon-triangle ribbon-top-end border-primary">
                                  <div class="ribbon-icon mt-n3 me-0">
                                    <i class="fas fa-star fs-2 text-white font-18"></i>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <Modal show={modalOpen} onHide={toggleModal} centered size="md" backdrop="static">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-1">
                  <textarea
                    id="message"
                    className="form-control"
                    rows={5}
                    name="message"
                    onChange={handleChange}
                    defaultValue={formData.message}
                  />
                  {errors.message && <span className="error">{errors.message}</span>}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default ShortListedJobs;
